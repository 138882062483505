import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { graphql } from "gatsby";

import SEO from "../components/globals/seo";
import Section from "../components/containers/section";
import Box from "../components/containers/box";
import Flex from "../components/containers/flex";
import Image from "../components/globals/image";
import Layout from "../components/containers/layout";
import Container from "../components/containers/container";
import Heading from "../components/typography/heading";
import Text from "../components/typography/text";
import TextWithLink from "../components/links/textWithLink";
import { Absolute, Relative } from "../components/containers/position";
import MaxWidthAbsolute from "../components/containers/maxWidthAbsolute";
import AppStoreLinks from "../components/links/appstoreLinks";
import PageTitle from "../components/typography/pageTitle";
import Circle from "../components/globals/circle";

import Carousel from "../components/carousel/carousel";
import CarouselItem from "../components/carousel/carouselItem";
import CarouselQuote from "../components/carousel/carouselQuote";
import CarouselButton from "../components/carousel/carouselButton";
import Counter from "../components/carousel/counter";
import ProfileComponent from "../components/profile/profile";
import useCarousel from "../hooks/useCarousel";

const Profile = styled(ProfileComponent)`
  z-index: 5;
  position: relative;
  margin: 20px 0;

  @media (min-width: ${p => p.theme.screenM}) {
    margin-top: -40px;
    margin-left: 40px;
  }
`;

const ImageWrap = styled(Relative)`
  min-width: 300px;

  img {
    margin: 0 auto;
    max-height: 625px;
    border-radius: 10px;
  }
`;

const QuoteWrap = styled(Box)`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${p => p.theme.screenM}) {
    margin-left: 60px;
    max-width: 370px;
  }
  @media (min-width: ${p => p.theme.screenL}) {
    margin-left: 100px;
  }
`;

const ParentPortalPage = ({ data, theme }) => {
  const testimonialList = data?.prismic?.data?.testimonialList || [];
  const hasMultipleItems = testimonialList.length > 1;
  const { slideIndex, onDrag: onCarouselDrag, onNextClick: onCarouselNextClick } = useCarousel(testimonialList.length);

  return (
    <Layout>
      <SEO
        title="Parent Portal"
        description="Find out how parents can monitor and control their childs progress through the TopTekkers app."
        path="/parent-portal"
      />

      <Section>
        <Container>
          <PageTitle>FOR PARENTS</PageTitle>
        </Container>

        <Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          className="posRelative"
          maxWidth="1600px"
          mx="auto"
        >
          <Container width={[1, null]} order={[2, null, 1]}>
            <Heading as="h1" maxWidth={[null, null, 480, 580, 680]}>
              Help your child get active with challenges to take them to the
              next level
            </Heading>
          </Container>

          <Absolute
            position={[null, null, `absolute`]}
            width={[`130%`, null, 2 / 3]}
            maxWidth="1600px"
            order={[1, null, 2]}
            mb={[20, null, 0]}
            px={[20, 30, 0]}
            right={[null, -150, null, null, -200]}
            bottom={[`-26px`, null, `-20px`, `-48px`]}
          >
            <Image
              fluid={data.challengeScreens.childImageSharp.fluid}
              alt="Previews of TopTekkers app challenge section inside mobile phone"
              maxWidth={[null, null, 870]}
            />
          </Absolute>

          <Container
            width={[1, null]}
            order={[3, null, 1]}
            maxWidth={1200}
            alignSelf="flex-start"
            zIndex={5}
          >
            <TextWithLink
              setWidth="370px"
              btnColor={theme.green}
              buttonText="Try it for Free!"
              href="https://app.toptekkers.com"
            >
              With 10 different skill categories and over 40 techniques to
              master, your child will have everything they need to improve
              their overall game, whilst having fun and keeping fit.
              Every technique and challenge in the app has a video to guide
              your child so they are fully supported in their skill development.
            </TextWithLink>
          </Container>
        </Flex>

        <MaxWidthAbsolute>
          <Circle
            grow
            bgColor={theme.yellow}
            top={0}
            right={[null, -46, null, -83]}
            hideOnMobile
          />
          <Circle
            growMid
            bgColor={theme.orange}
            top={[-70, null, -90, -130]}
            right={[`20%`, null, `40%`]}
          />
          <Circle
            growMid
            bgColor={theme.purple}
            bottom={[`30%`, `10%`]}
            left={[-40, -48]}
          />
        </MaxWidthAbsolute>
      </Section>

      <Section>
        <Container>
          <Heading as="h2" maxWidth={[null, null, 578]}>
            Safe and secure. Control connections to your child’s coaches
          </Heading>
          <Flex flexWrap="wrap" alignItems="center">
            <Box
              width={[1, null, 1 / 2]}
              mb={[30, null, 0]}
              className="posRelative"
            >
              <Image
                fluid={data.myConnections.childImageSharp.fluid}
                alt="Example of how parents can control player connections and subscriptions inside TopTekkers app."
              />
              <Circle
                growMid
                bgColor={theme.red}
                top={[-30, null, -38]}
                right={`5%`}
              />
              <Circle
                grow
                bgColor={theme.blue}
                bottom={[-30, null, -46, -83]}
                left={[-30, null, -46, -83]}
              />
            </Box>

            <Box
              width={[1, null, 1 / 2]}
              max-Width={[null, null, null, 370]}
              pl={[0, null, 30, 40, 70]}
            >
              <TextWithLink
                btnColor={theme.green}
                buttonText="Create an account"
                href="https://app.toptekkers.com"
              >
                As a parent, you have control over your child&apos;s connections
                and can securely connect with their coach so your child can see
                team training plans and leaderboards.
              </TextWithLink>
            </Box>
          </Flex>
        </Container>
      </Section>

      {testimonialList.length > 0 && (
      <Section>
        <Container>
          <Heading
            as="h2"
            textAlign="center"
            mx="auto"
            mb={[`40px`, null, null, `60px`, `80px`]}
            maxWidth="578px"
            className="posRelative"
          >
            Used by the World’s best coaches
          </Heading>

          <Circle
            grow
            bgColor={theme.yellow}
            top="0"
            right={[-30, null, -46, -83]}
          />
        </Container>

        <Carousel
          slideIndex={slideIndex}
          onCarouselDrag={onCarouselDrag}
          hasMultipleItems={hasMultipleItems}
        >
          {testimonialList.map((entry, i) => {
            const { data: entryData } = entry.testimonial.document;
            const mainImage = entryData.main_image.localFile.childImageSharp.fluid;
            const profileData = { name: entryData.profile_name, title: entryData.profile_title, img: entryData.profile_image };

            return (
              // eslint-disable-next-line react/no-array-index-key
              <Container key={i}>
                <CarouselItem flexDirection={[`column`, null, `row`]}>
                  <Box>
                    <ImageWrap>
                      <Image fluid={mainImage} alt={entryData.main_image.alt} />

                      {hasMultipleItems && (
                        <>
                          <Absolute
                            top={[`0`, null, `unset`]}
                            bottom={[`0`, null, `10%`]}
                            right={[`0`, null, `-46px`]}
                            zIndex="5"
                          >
                            <CarouselButton onClick={onCarouselNextClick} />
                          </Absolute>

                          <Counter
                            current={i + 1}
                            max={testimonialList.length}
                          />
                        </>
                      )}

                      <Circle
                        grow
                        bgColor={theme.pink}
                        bottom="25%"
                        left={[-30, null, -46, -83]}
                        hideOnMobile
                      />
                    </ImageWrap>

                    <Profile
                      maxWidth={[`100%`, null, null, `300px`]}
                      data={profileData}
                      my={[`20px`, null, 0]}
                      mb="80px"
                      alignItems={[`center`, null, `flex-start`]}
                      rowOnMob
                    />
                  </Box>

                  <QuoteWrap>
                    <CarouselQuote
                      quote={entryData.testimonial}
                      highlight={entryData.highlight}
                    />
                  </QuoteWrap>
                </CarouselItem>
              </Container>
            );
          })}
        </Carousel>
      </Section>
      )}

      <Section>
        <Container>
          <Flex flexWrap="wrap" alignItems="center">
            <Box
              width={[1, null, 1 / 2]}
              order={[2, null, 1]}
              pr={[0, null, 30, 40, 70]}
            >
              <Heading as="h2" setWidth="518px">
                Track your child’s progress on the go in the Parent Portal
              </Heading>

              <Text>
                Stay engaged in your child&apos;s development and view their
                scores and trophies in the app.
              </Text>

              <AppStoreLinks />
            </Box>

            <Relative
              width={[1, null, 1 / 2]}
              order={[1, null, 2]}
              mb={[20, null, 0]}
              className="posRelative"
            >
              <Image
                fluid={data.childProgress.childImageSharp.fluid}
                alt="Mobile app views showing how parents can track the childs progress whilst on the go using the TopTekkers app."
              />

              <Circle
                growMid
                bgColor={theme.pink}
                bottom={`30%`}
                right={[-30, null, -42]}
              />
              <Circle
                grow
                bgColor={theme.yellow}
                top={[-6, null, -36, -60]}
                left={[`46%`, null, `38%`]}
              />
            </Relative>
          </Flex>
        </Container>
      </Section>

      <Section>
        <Flex
          flexWrap="wrap"
          alignItems="center"
          className="posRelative"
          maxWidth="1600px"
          mx="auto"
        >
          <Container width={1}>
            <Heading
              as="h2"
              maxWidth={[null, null, 480, null, 580, 680]}
              className="posRelative"
            >
              As your child progresses you can easily adjust the difficulty
              level of the skills they see and practice
            </Heading>

            <Circle
              growMid
              bgColor={theme.orange}
              top={[-20, null, -42]}
              left={[-5, null, 0, null, 80, 110]}
            />
          </Container>

          <Absolute
            position={[null, null, `absolute`]}
            pl={[20, 30, 0]}
            pb={[30, null, 0]}
            right={30}
            width={[1 / 1.5, null, 1 / 2.5]}
          >
            <Image
              fluid={data.playerLevels.childImageSharp.fluid}
              alt="illustration of players and rosettes showing different levels"
            />

            <Circle
              grow
              bgColor={theme.purple}
              top={[200, null, 100]}
              right={[-30, null, -46, -100]}
            />
          </Absolute>

          <Container width={1} pr={[0, null, 30, 40, 70]}>
            <TextWithLink
              setWidth="370px"
              btnColor={theme.green}
              buttonText="Try it for free!"
              href="https://app.toptekkers.com"
            >
              We recommend difficulty based on your child's age, but we know
              every child is different, so you can choose to select a
              different level in the app.
            </TextWithLink>
          </Container>
        </Flex>
      </Section>

      <Section maxWidth="none" pb="0">
        <MaxWidthAbsolute>
          <Circle
            grow
            bgColor={theme.pink}
            top={[-28, null, -46, -76]}
            left={[-28, null, -46, -90]}
          />
          <Circle
            growMid
            bgColor={theme.yellow}
            bottom={`10%`}
            left={[-28, null, -46]}
          />
          <Circle
            growMid
            bgColor={theme.blue}
            bottom={`40%`}
            left={`14%`}
            hideOnMobile
          />
          <Circle
            grow
            bgColor={theme.purple}
            top={`44%`}
            right={[-28, null, -46, -90]}
            hideOnMobile
          />
        </MaxWidthAbsolute>

        {/* Overlay */}
        <Absolute
          bg="rgba(255, 255, 255, 0.9)"
          borderTop="1px solid #EBEBEB"
          top="0"
          bottom="0"
          left="0"
          right="0"
        />

        {/* Content */}
        <Container pt={[60, null, null, 100]} pb={[60, null, 70]}>
          <Heading as="h3" maxWidth="785px" textAlign="center" mx="auto">
            Do you want to help your child become the best player they can be?
          </Heading>

          <TextWithLink
            setCenter
            btnColor={theme.green}
            buttonText="Create an account"
            setWidth="579px"
            href="https://app.toptekkers.com"
          >
            Talent is important, but it's only part of the equation. Every
            player should have a relentless enthusiasm for improving their
            skills to be the best they can be.  TopTekkers supports your
            players in their practise in a fun and challenging way.
          </TextWithLink>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    challengeScreens: file(relativePath: { eq: "images/challengeScreens.png" }) {
      childImageSharp {
        fluid(maxWidth: 875) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    myConnections: file(relativePath: { eq: "images/myConnections.png" }) {
      childImageSharp {
        fluid(maxWidth: 725) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    childProgress: file(relativePath: { eq: "images/childProgress.png" }) {
      childImageSharp {
        fluid(maxWidth: 670) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    playerLevels: file(relativePath: { eq: "images/playerLevels.png" }) {
      childImageSharp {
        fluid(maxWidth: 888) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    prismic: prismicTestimonialList(data: {page: {eq: "Parent Portal"}}) {
      data {
        page
        testimonialList: testimonial_list {
          testimonial {
            document {
              ... on PrismicTestimonial {
                data {
                  main_image {
                    alt
                    localFile {
                      ...imageFragmentMax
                    }
                  }
                  highlight
                  testimonial
                  profile_image {
                    alt
                    localFile {
                      ...imageFragmentProfilePic
                    }
                  }
                  profile_name
                  profile_title
                }
              }
            }
          }
        }
      }
    }
  }
`;

ParentPortalPage.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(ParentPortalPage);
